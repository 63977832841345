/* global graphql */
import React from 'react';
import TemplateWrapper from '../components/TemplateWrapper';
import HeaderTech from '../components/header/header-tech';
import InfoSectionSplit from '../components/InfoSection/InfoSectionSplit';
import CTAForm from '../components/CTAForm';
import ImageTextOverlay from '../components/ImageTextOverlay';
import {Link} from "gatsby";
import { graphql } from "gatsby";

const TechPage = props =>
  (<TemplateWrapper location={props.location}>
    <HeaderTech location={props.location.pathname} />
    <main className="TechPage">
      <section className="Section TechPage-heading">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 col-md-12 col-md-offset-1 col-lg-8">
                <h1 className="Heading Heading-d1 light TechPage-heading-title">Magic WiFi</h1>
                <p className="BodyText BodyText-B1">Forget about captive portals and staying safe on unsecure WiFi. Magic WiFi powers a instant-on WiFi experience that’s encrypted end-to-end.</p>
                <div className="TechPage-heading-cta">
                  <Link to="/waitlist/" className="BtnFilled BtnFilled-large purple uppercase" onClick={()=>window.analytics.track('Clicked Waitlist CTA',{component:'techPage'})}><span>Sign up</span></Link>
                  <p className="Caption Caption-C3 light uppercase">Now Available</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Section TechPage-info1">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 col-md-12 col-md-offset-1">
                <InfoSectionSplit data={props.data.allDataJson.edges[0].node.TechInfo1} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Section TechPage-pic1">
        <ImageTextOverlay imageLink="https://s3.us-east-2.amazonaws.com/hologram-static/magic/marketing/tech_employees-2.png" imageText="Join the network in Autumn 2018" />
      </section>
      <section className="Section TechPage-info2">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 col-md-12 col-md-offset-1">
                <InfoSectionSplit data={props.data.allDataJson.edges[0].node.TechInfo2} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Section TechPage-info3">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 col-md-12 col-md-offset-1">
                <InfoSectionSplit data={props.data.allDataJson.edges[0].node.TechInfo3} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Section TechPage-CTAForm">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 col-md-12 col-md-offset-1">
                <CTAForm text="Sign up now to try out Magic WiFi for yourself and your devices. Available for free this Autumn." />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </TemplateWrapper>);

export default TechPage;

export const pageQuery = graphql`
  query TechQuery {
    allDataJson {
      edges {
        node {
          TechInfo1 {
            title,
            subtitle,
            paragraph1,
            paragraph2,
            color
          }
          TechInfo2 {
            title,
            subtitle,
            paragraph1,
            paragraph2,
            paragraph3
            color
          }
          TechInfo3 {
            title,
            subtitle,
            paragraph1,
            paragraph2,
            color
          }
        }
      }
    }
  }
`;

